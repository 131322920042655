<template>
  <div
    v-if="showIfEmptyOnStage"
    class="card-section"
  >
    <div class="card-section-header">
      <div class="card-section-header-name">
        {{ $companyConfiguration.cardSections.getSectionConfig(section.type).showName }}
      </div>

      <el-popover
        v-if="$companyConfiguration.cardSections.getSectionConfig(section.type).description"
        placement="top"

        trigger="click"
      >
        <el-button
          slot="reference"
          style="margin-left:10px"
          class="table-action-button danger"
          type="text"
          round
          size="mini"
          icon="fas fa-info-circle"
        ></el-button>

        <div style="white-space: pre-line" v-html="$companyConfiguration.cardSections.getSectionConfig(section.type).description"></div>
      </el-popover>


      <el-button
        v-if="$canAndRulesPass(section, $permissions.CARD_ELEMENT.CREATE)"
        style="margin-left:30px"
        size="mini"
        icon="fas fa-plus"
        @click="addElement"
      >
<!--        Текст кнопки может быть задан в настройках самой секции-->
        {{ $companyConfiguration.cardSections.getSectionConfig(this.section.type).addElementButtonText || $companyConfiguration.i18n.t('card', 'section_add_element_button') }}
      </el-button>
    </div>


    <el-table
      class="card-section-elements-table"
      header-row-class-name="card-section-elements-table-header"
      :show-header="!!elements.length"
      style="width: 100%"
      border
      :max-height="$companyConfiguration.cardSections.getSectionConfig(section.type).limitHeight ? maxHeightOfSection : null"
      :key="tableKey"
      size="mini"
      :data="elements"
      :empty-text="$companyConfiguration.cardSections.getSectionConfig(section.type).emptyText"
      v-loading="loading"
    >
      <component
        v-for="(columnConfig, index) in columnsConfigs"
        v-if="ifShowColumn(columnConfig)"
        :is="columnConfig.manualColumnTemplate ? columnConfig.template : baseTableColumn"
        :key="index"
        :column-config="columnConfig"
        @elements-changed="elementsChanged"
      >
      </component>
    </el-table>


    <element-add-from-library-dialog
      ref="addElementFromLibraryDialog"
      :from-card="card.id"
      @element-ids-selected="createElementsFromLibrary"
    >
    </element-add-from-library-dialog>

  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit";
import CardElementScaleEditDialog from "@/components/cards/CardElementsBlock/CardElementScaleEditDialog";
import ElementAddFromLibraryDialog from "@/components/cards/CardElementsBlock/ElementAddFromLibraryDialog";
import ElementNameTableColumn from "@/components/tableColumnTemplates/card/ElementNameTableColumn";
import {mapGetters} from "vuex";

export default {
  name: "card-section",
  components: {
    ElementNameTableColumn, ElementAddFromLibraryDialog, CardElementScaleEditDialog, ClickToEdit
  },

  props: {
    section: {type: Object, required: true},
  },

  computed: {
    ...mapGetters(['user']),
    ...mapGetters('card', {
      card: 'card',
    }),
    ...mapGetters('card/stages', {
      currentStage: 'currentStage',
      currentNegotiation: 'currentNegotiation',
    }),
    ...mapGetters('card/sections', {
      sectionElements: 'sectionElements',
      sectionLoading: 'sectionLoading',
    }),
    elements(){
      return this.sectionElements(this.section.id);
    },
    loading(){
      return this.sectionLoading(this.section.id);
    },

    baseTableColumn() {
      return () => import(`@/components/tableColumnTemplates/BaseTableColumn`);
    },
    showIfEmptyOnStage(){
      if( this.elements.length ){
        return true;
      }
      return !this.$companyConfiguration.cardSections.hideOnStagesIfEmpty(this.section.type, (this.currentStage ? this.currentStage.type : '' ))
    }
  },
  data() {
    return {
      maxHeightOfSection: null,
      columnsConfigs: [],
      tableKey: 1,
    }
  },
  beforeMount() {
    this.columnsConfigs = this.$companyConfiguration.helpers.getTableColumnsArrayFromConfig(
      this.$companyConfiguration.cardSections.getSectionColumns(this.section.type)
    );
  },
  mounted() {
    this.loadElements();
    this.maxHeightOfSection = window.innerHeight - 100;
  },
  methods: {
    loadElements() {
      this.$store.dispatch('card/sections/loadSectionElements', {
        section_id: this.section.id,
        expand: [
          'scale',
          'permissions',
          'fields_permissions',
          'last_comment_by_stage',
          'comments_count_by_stage',
          (this.$companyConfiguration.card.getElementMediumFactsConfig()['available'] ? 'medium_facts' : null),
        ].join(),
      })
        .then(() => {
          // Перерисовываем таблицу, чтобы корректно отображать fixed колонки
          this.tableKey++;
        });
    },

    ifShowColumn(columnConfig){
      if( !columnConfig.getVif(this) ){
        return false;
      }

      if( columnConfig.showOnStages ){
        if( this.card.status === this.$constants.card.statuses.STATUS_COMPLETED ){
          return columnConfig.showOnCompleted;
        }

        // У нас может совпасть stage, но у этого stage может не быть еще активного negotiation.
        // Поэтому нужно проверить не только stage, но и наличие активного negotiation
        // НО! Может быть так, что колонка должна быть на этапе без проверки negotiation
        // Например Промежуточное выполнение на этапе оценки. Если нет активного шага на оценке,
        // То и колонка не будет видна, хотя она заполнялась на предыдущем этапе
        // Для этого ввели showOnStagesWhileNoNegotiation,
        // где можно перечислить, для каких этапов мы пропускаем проверку активного шага
        if( !this.currentNegotiation ){
          const showWhileNoNegotiation = columnConfig.showOnStagesWhileNoNegotiation ? columnConfig.showOnStagesWhileNoNegotiation : [];
          return showWhileNoNegotiation.includes(this.currentStage?.type)
        }

        return columnConfig.showOnStages.includes(this.currentStage?.type);
      }

      return true;
    },

    addElement() {
      let fromLibraryConfig = this.$companyConfiguration.cardSections.getSectionConfig(this.section.type)['fromLibrary'];
      if (fromLibraryConfig) {
        this.addElementFromLibrary(fromLibraryConfig);
      } else {
        this.createElement();
      }
    },

    createElement() {
      let data = {
        section_id: this.section.id,
      }
      // autofill scale_type if only one in system
      const allowedScaleTypesForSection = this.$companyConfiguration.cardSections.getAllowedScaleTypesForSections(this.section.type);
      if( allowedScaleTypesForSection.length === 1 ){
        data.scale_type = allowedScaleTypesForSection[0];
      }
      this.$api.card.createElement(data)
        .then((data) => {
        })
        .finally(() => {
          this.loadElements();
          this.$emit('elements-changed');
        })
    },


    elementsChanged() {
      this.loadElements();
      this.$emit('elements-changed');
    },


    addElementFromLibrary(libraryType) {
      this.$refs.addElementFromLibraryDialog.open(libraryType);
    },
    createElementsFromLibrary(libraryElementIds) {
      this.$api.card.createElementsFromLibrary({
        section_id: this.section.id,
        element_ids: libraryElementIds,
      })
        .then((data) => {
        })
        .finally(() => {
          this.$refs.addElementFromLibraryDialog.close();
          this.loadElements();
          this.$emit('elements-changed');
        })
    },
  }
}
</script>


<style scoped lang="scss">
.card-section {
  margin-bottom: 30px;

  &-header {
    font-weight: bold;
    margin-bottom: 5px;

    &-name {
      display: inline-block;
      min-width: 100px;
    }
  }
}

</style>